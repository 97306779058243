import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import styled from "styled-components";

import { colors } from "../styles/colors";
import { H3, H4, H5, H6, P } from "../styles/text-styles";

interface BlogContentProps {
  content: any;
}

export default function BlogContent(props: BlogContentProps) {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: (text: string) => <Italic>{text}</Italic>,
      [MARKS.UNDERLINE]: (text: string) => <Underline>{text}</Underline>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_: any, children: any) => (
        <Paragraph>{children}</Paragraph>
      ),
      [BLOCKS.HEADING_1]: (_: any, children: any) => <H3>{children}</H3>,
      [BLOCKS.HEADING_2]: (_: any, children: any) => <H4>{children}</H4>,
      [BLOCKS.HEADING_3]: (_: any, children: any) => <H5>{children}</H5>,
      [BLOCKS.HEADING_4]: (_: any, children: any) => <H6>{children}</H6>,
      [BLOCKS.HEADING_5]: (_: any, children: any) => <H6>{children}</H6>,
      [BLOCKS.HEADING_6]: (_: any, children: any) => <H6>{children}</H6>,

      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const { gatsbyImageData, title } = node.data.target;
        return (
          <EmbeddedImageWrapper>
            <EmbeddedImage image={getImage(gatsbyImageData)!} alt={title} />;
          </EmbeddedImageWrapper>
        );
      },

      [INLINES.HYPERLINK]: (node: any, children: any) => (
        <HyperLink
          id="blog_link_click"
          href={node.data.uri}
          target={`${node.data.uri.startsWith("/") ? "_self" : "_blank"}`}
          rel={`${node.data.uri.startsWith("/") ? "" : "noopener noreferrer"}`}
        >
          {children}
        </HyperLink>
      ),
    },
  };

  return <Wrapper>{renderRichText(props.content, options)}</Wrapper>;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Bold = styled(P)`
  display: inline;
`;

const Italic = styled(P)`
  display: inline;
  color: ${colors.gray4};
  font-style: italic;
`;

const Underline = styled(P)`
  display: inline;
  color: ${colors.gray4};
  text-decoration: underline;
`;

const Paragraph = styled(P)`
  color: ${colors.gray4};
`;

const EmbeddedImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const EmbeddedImage = styled(GatsbyImage)`
  border-radius: 12px;
  max-width: 640px;
  max-height: 640px;
`;

const HyperLink = styled.a`
  color: ${colors.white};
  text-decoration: underline;

  :hover {
    filter: brightness(0.9);
  }
`;
