import BlogContent from "../components/BlogContent";
import PillLabel from "../components/PillLabel";
import Layout from "../components/meta/Layout";
import Seo from "../components/meta/Seo";
import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { Section } from "../styles/structures";
import { H1, H5, P } from "../styles/text-styles";
import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

export const blogQuery = graphql`
  query BlogQuery($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      description
      category
      thumbnail {
        url
      }

      updatedAt(formatString: "MMMM d, Y")

      author {
        name
        image {
          url
        }
      }

      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
    }
  }
`;

export default function Blog(props) {
  const { data } = props;
  const blogPostData = data.contentfulBlogPost;

  const authorFirstName = blogPostData.author.name.split(" ")[0];

  return (
    <Layout>
      <Seo title={blogPostData.title} description={blogPostData.description} />
      <Section>
        <Wrapper>
          <Heading>
            {blogPostData.category && (
              <PillLabel label={blogPostData.category} />
            )}
            <H1>{blogPostData.title}</H1>
            <CreditWrapper>
              <AuthorThumbnail
                src={blogPostData.author.image.url}
                alt={blogPostData.author.name}
              />
              <CreditMetadata>
                <DateText>{blogPostData.updatedAt}</DateText>
                <P>by {authorFirstName}</P>
              </CreditMetadata>
            </CreditWrapper>
          </Heading>
          <BlogImage
            src={blogPostData.thumbnail.url}
            alt={blogPostData.title}
          />
          <DescriptionText>{blogPostData.description}</DescriptionText>
          <BlogContent content={blogPostData.content} />
        </Wrapper>
      </Section>
    </Layout>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${breakpoints.md} {
    gap: 96px;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AuthorThumbnail = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;

  ${breakpoints.md} {
    height: 48px;
    width: 48px;
    border-radius: 24px;
  }
`;

const CreditMetadata = styled.div``;

const CreditWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const DateText = styled(P)`
  color: ${colors.gray4};
`;

const DescriptionText = styled(H5)`
  color: ${colors.gray4};
`;

const BlogImage = styled.img`
  border-radius: 12px;
  width: 100%;
  height: 100%;
`;
